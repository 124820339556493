<template>
  <div>
    <b-sidebar
      sidebar-class="sidebar-lg"
      visible
      bg-variant="white"
      shadow
      backdrop
      no-header
      right
      @change="closeModal"
    >
      <template #default="{ hide }">

        <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
          <h5
            class="mb-0"
          >
            {{ row.name }}
          </h5>
          <div>
            <feather-icon
              class="ml-1 cursor-pointer"
              icon="XIcon"
              :size="sizeIconsAction"
              @click="hide"
            />
          </div>
        </div>

        <b-container
          class="p-2"
          fluid
        >
          <!-- Link para fazer chamada para telefone, whatsapp e email -->
          <b-button
            v-if="row.phone"
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="outline-secondary"
            block
            target="_blank"
            :href="`tel:${removeAllSpaces(row.phone)}`"
            class="text-truncate"
          >
            <feather-icon
              icon="PhoneIcon"
              class="mr-50"
            />
            <span class="align-middle">{{ row.phone }}</span>
          </b-button>

          <b-button
            v-if="row.phone"
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="outline-secondary"
            block
            class="text-truncate"
            @click.stop.prevent="openAppWhatsapp(`${removeAllSpaces(row.phone)}`)"
          >
            <b-img
              :src="require('@/assets/images/icons/whatsapp.svg')"
              class="mr-50"
              height="14"
            />
            <span class="align-middle">{{ row.phone }}</span>
          </b-button>

          <b-button
            v-if="row.email"
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="outline-secondary"
            block
            target="_blank"
            :href="`mailto:${row.email}`"
            class="text-truncate"
          >
            <feather-icon
              icon="MailIcon"
              class="mr-50"
            />
            <span class="align-middle">{{ row.email }}</span>
          </b-button>

          <div class="blockSepButtons mt-1 mb-1" />

          <!-- Vista completa -->
          <b-button
            v-if="getNameAction('viewDetail') !== ''"
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="outline-secondary"
            block
            :to="{ name: 'crm-leads-view', params: { id: row.id } }"
            @click.stop.prevent="closeModal"
          >
            <span class="align-middle">{{ getNameAction('viewDetail') }}</span>
          </b-button>

          <!-- Editar -->
          <b-button
            v-if="getNameAction('edit') !== ''"
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="outline-secondary"
            block
            :to="{ name: 'crm-leads-edit', params: { id: row.id } }"
            @click.stop.prevent="closeModal"
          >
            <span class="align-middle">{{ getNameAction('edit') }}</span>
          </b-button>

          <!-- Consentimento -->
          <b-button
            v-if="getNameAction('swLinkConsentimento') !== ''"
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="outline-secondary"
            block
            @click.stop.prevent="modalConsentimentoAction(row.id)"
          >
            <span class="align-middle">{{ getNameAction('swLinkConsentimento') }}</span>
          </b-button>

          <!-- Partilhar lead -->
          <b-button
            v-if="getNameAction('sendLeadToOtherConsultor') !== ''"
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="outline-secondary"
            block
            @click.stop.prevent="shareLead(row.id, row.name)"
          >
            <span class="align-middle">{{ getNameAction('sendLeadToOtherConsultor') }}</span>
          </b-button>

          <!-- Enviar referencia -->
          <b-button
            v-if="getNameAction('sendRefToOtherConsultor') !== ''"
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="outline-secondary"
            block
            @click.stop.prevent="sendReference(row.id, row.name)"
          >
            <span class="align-middle">{{ getNameAction('sendRefToOtherConsultor') }}</span>
          </b-button>

          <!-- Atribuir -->
          <b-button
            v-if="getNameAction('shareLeadWithLeaderOrConsultor') !== '' && showBtnChangeResp == true"
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="outline-secondary"
            block
            @click.stop.prevent="shareLeadWithLeaderOrConsultor(row.id, row.name)"
          >
            <span class="align-middle">{{ getNameAction('shareLeadWithLeaderOrConsultor') }}</span>
          </b-button>

          <!-- Adicionar -->
          <b-button
            v-if="getNameAction('shareAddLeadWithLeaderOrConsultor') !== '' && showBtnChangeResp == true"
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="outline-secondary"
            block
            @click.stop.prevent="shareAddLeadWithLeaderOrConsultor(row.id, row.name)"
          >
            <span class="align-middle">{{ getNameAction('shareAddLeadWithLeaderOrConsultor') }}</span>
          </b-button>

          <!-- Transferir -->
          <b-button
            v-if="getNameAction('modeLeaderChangeTabLead') !== '' && showBtnTransfer == true"
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="outline-secondary"
            block
            @click.stop.prevent="changeTabLead(row.id, row.name)"
          >
            <span class="align-middle">{{ getNameAction('modeLeaderChangeTabLead') }}</span>
          </b-button>

          <!-- Converter Lead para Contacto -->
          <b-button
            v-if="getNameAction('convertLeadToContacto') !== ''"
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="outline-secondary"
            block
            @click.stop.prevent="convertLeadToContacto(row.id, row.name)"
          >
            <span class="align-middle">{{ getNameAction('convertLeadToContacto') }}</span>
          </b-button>

          <!-- Converter Contacto para Lead -->
          <b-button
            v-if="getNameAction('convertContactoToLead') !== ''"
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="outline-secondary"
            block
            @click.stop.prevent="convertContactoToLead(row.id, row.name)"
          >
            <span class="align-middle">{{ getNameAction('convertContactoToLead') }}</span>
          </b-button>

          <!-- Converter Contacto para Lead (escolha) -->
          <b-button
            v-if="getNameAction('contactoToLead') !== ''"
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="outline-secondary"
            block
            @click.stop.prevent="convertContactoToLeadChooseTab(row.id, row.name)"
          >
            <span class="align-middle">{{ getNameAction('contactoToLead') }}</span>
          </b-button>

          <!-- Converter Contacto para Lead (link) -->
          <b-button
            v-if="getNameAction('convertContactoToLeadLink') !== ''"
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="outline-secondary"
            block
            @click.stop.prevent="convertContactoToLeadLink(row.id)"
          >
            <span class="align-middle">{{ getNameAction('convertContactoToLeadLink') }}</span>
          </b-button>

          <!-- Restaurar -->
          <b-button
            v-if="getNameAction('restoreSW025') !== ''"
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="outline-secondary"
            block
            @click.stop.prevent="restore(row.id, getTypeReg())"
          >
            <span class="align-middle">{{ getNameAction('restoreSW025') }}</span>
          </b-button>

          <!-- Eliminar -->
          <b-button
            v-if="getNameAction('removeRegAction') !== '' && showBtnDelete === true"
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="outline-secondary"
            block
            @click.stop.prevent="remove(row.id, getTypeReg(), getValueRow('sw025s81'))"
          >
            <span class="align-middle">{{ getNameAction('removeRegAction') }}</span>
          </b-button>

          <!-- Guardar (Partilha) -->
          <b-button
            v-if="getNameAction('saveLeadShareToUser') !== ''"
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="outline-info"
            block
            @click.stop.prevent="saveLeadShareToUser(row.id, row.name)"
          >
            <span class="align-middle">{{ getNameAction('saveLeadShareToUser') }}</span>
          </b-button>

          <!-- Aceitar (Referencia) -->
          <b-button
            v-if="getNameAction('saveLeadRefToUser') !== ''"
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="outline-info"
            block
            @click.stop.prevent="saveLeadRefToUser(row.id, row.name, true)"
          >
            <span class="align-middle">{{ $t('Aceitar') }}</span>
          </b-button>

          <!-- Rejeitar (Referencia) -->
          <b-button
            v-if="getNameAction('saveLeadRefToUser') !== ''"
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="outline-danger"
            block
            @click.stop.prevent="saveLeadRefToUser(row.id, row.name, false)"
          >
            <span class="align-middle">{{ $t('Rejeitar') }}</span>
          </b-button>

          <!--
          Acoes para depois se imlementar
          createNewVisitaLead
          convertUserToAliadosGeral
          sendRefRecrutamentoToUser
          swLinkCreditProcess
          saveRefRecrutToUser
          swsModuleFornecedorAddBusinessContact
          viewHistoryActivityCandidato
          convertUserToCandidatoRecrutamento
          -->

        </b-container>

      </template>
    </b-sidebar>
  </div>
</template>

<script>
import {
  BSidebar, BButton, BContainer, BImg,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { sizeIconsAction } from '@core-custom/mixins/geral'
import AppAvailability from 'cordova-plugin-appavailability/www/AppAvailability'
import useCapacitor from '@core-custom/utils/capacitor'
import checkNested from 'check-nested'
import { AppLauncher } from '@capacitor/app-launcher'

export default {
  components: {
    BSidebar,
    BButton,
    BContainer,
    BImg,
  },
  directives: {
    Ripple,
  },
  mixins: [sizeIconsAction],
  props: {
    row: {
      type: Object,
      required: true,
    },
    extra: {
      type: Object,
      required: true,
    },
    remove: {
      type: Function,
      required: true,
    },
    restore: {
      type: Function,
      required: true,
    },
    shareLead: {
      type: Function,
      required: true,
    },
    sendReference: {
      type: Function,
      required: true,
    },
    shareLeadWithLeaderOrConsultor: {
      type: Function,
      required: true,
    },
    shareAddLeadWithLeaderOrConsultor: {
      type: Function,
      required: true,
    },
    changeTabLead: {
      type: Function,
      required: true,
    },
    convertLeadToContacto: {
      type: Function,
      required: true,
    },
    convertContactoToLead: {
      type: Function,
      required: true,
    },
    convertContactoToLeadLink: {
      type: Function,
      required: true,
    },
    convertContactoToLeadChooseTab: {
      type: Function,
      required: true,
    },
    modalConsentimentoAction: {
      type: Function,
      required: true,
    },
    saveLeadShareToUser: {
      type: Function,
      required: true,
    },
    saveLeadRefToUser: {
      type: Function,
      required: true,
    },
  },
  data() {
    return {
      showBtnTransfer: false,
      showBtnBookmarks: false,
      showBtnChangeResp: false,
      showBtnDelete: false,
    }
  },
  async mounted() {
    const oCurrentUser = this.$store.getters['auth/currentUser']
    const tabSelectFilter = this.$store.getters['crm_leads/tabSelectFilter']
    if (oCurrentUser !== null && oCurrentUser !== undefined && checkNested(oCurrentUser, 'extras.basic') && oCurrentUser.extras.basic === 1) {
      this.showBtnTransfer = true
      this.showBtnBookmarks = true
      this.showBtnChangeResp = true
      this.showBtnDelete = true
    } else if (oCurrentUser !== null && oCurrentUser !== undefined) {
      if ('extras' in oCurrentUser && ('perm' in oCurrentUser.extras)) {
        if ((Object.values(oCurrentUser.extras.perm).includes('altera_favoritos_leads'))) {
          this.showBtnBookmarks = true
        }

        if ((Object.values(oCurrentUser.extras.perm).includes('pode_transferir_separador'))) {
          this.showBtnTransfer = true
        }

        if (tabSelectFilter !== 'lixo' && (Object.values(oCurrentUser.extras.perm).includes('pode_atribuir') || Object.values(oCurrentUser.extras.perm).includes('pode_enviar_leads_outros_sep'))) {
          this.showBtnChangeResp = true
        }

        if (Object.values(oCurrentUser.extras.perm).includes(`${tabSelectFilter}_A`)) {
          this.showBtnDelete = true
        }

        if (Object.values(oCurrentUser.extras.perm).includes('lixo_V')) {
          this.showBtnBookmarks = true
        }
      }
    }
  },
  methods: {
    closeModal() {
      this.$parent.closeModalActionByRow()
    },
    getNameAction(tag) {
      if ((this.extra !== null) && (this.extra !== undefined) && ('buttonsAction' in this.extra)) {
        const buttonAction = this.extra.buttonsAction.filter(row => (row.action === tag)).shift()

        if ((buttonAction !== null) && (buttonAction !== undefined) && ('txt' in buttonAction)) {
          return buttonAction.txt
        }
      }

      return ''
    },
    getTypeReg() {
      if ((this.extra !== null) && (this.extra !== undefined) && ('sw025s80_txt' in this.extra)) {
        return this.extra.sw025s80_txt || ''
      }

      return ''
    },
    getValueRow(field) {
      if ((this.extra !== null) && (this.extra !== undefined) && ('sw025' in this.extra)) {
        if (field in this.extra.sw025) {
          return this.extra.sw025[field] || 0
        }
      }

      return 0
    },
    removeAllSpaces(value) {
      return value.replace(/\s/g, '')
    },
    async openAppWhatsapp(number) {
      const platform = useCapacitor.getPlatform()

      if (useCapacitor.isNative() === true) {
        let app = ''
        let linkApp = ''
        if (platform === 'ios') {
          app = 'whatsapp://'
          linkApp = 'whatsapp://wa.me/'
        } else if (platform === 'android') {
          app = 'com.whatsapp'
          linkApp = 'whatsapp://send?phone='
        }

        AppAvailability.check(app,
          async () => {
            await AppLauncher.openUrl({ url: `${linkApp}${number}` })
          },
          async () => {
            await AppLauncher.openUrl({ url: `https://wa.me/${number}` })
          })
      } else {
        await AppLauncher.openUrl({ url: `https://wa.me/${number}` })
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.blockSepButtons{
  border-top: 1px solid $border-color;
}

.dark-layout .blockSepButtons{
  border-top: 1px solid $theme-dark-border-color;
}
</style>
